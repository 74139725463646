<template>
  <div
    v-if="$route.name !== 'tvPage' && $route.name !== 'mobilePage'"
    class="innovative"
  >
    <div class="container">
      <div class="innovative-content">
        <h2 class="innovative__title">
          {{ title }}
        </h2>
        <div class="innovative__desc description">
          {{ subTitle }}
        </div>

        <div class="innovative-tabs-bar">
          <div
            id="gallery-top"
            class="innovative-tabs gallery-top"
          >
            <swiper
              ref="swiperThumbs"
              :options="swiperOptionThumbs"
            >
              <swiper-slide
                v-for="(item, index) in innovativeTabs"
                :key="index"
                class="innovative-tabs__item"
                :class="{'innovative-tabs__item_active': activeSlideThumbs === index}"
              >
                {{ item.tabName }}
              </swiper-slide>
            </swiper>

            <div
              v-if="showLeftArrow"
              class="swiper-button-prev"
              @click="slideSwiperTo('prev')"
            >
              <i class="icon-arrow"></i>
            </div>
            <div
              v-if="showRightArrow"
              class="swiper-button-next"
              @click="slideSwiperTo('next')"
            >
              <i class="icon-arrow"></i>
            </div>
          </div>
        </div>

        <div
          id="gallery-thumbs"
          class="innovative-box gallery-thumbs wow fadeIn"
          data-wow-duration="2s"
        >
          <swiper
            ref="swiperTop"
            :options="swiperOptionTop"
          >
            <swiper-slide
              v-for="(item) in innovativeTabsContent"
              :key="item.id"
              class="innovative-box-item"
            >
              <img
                class="innovative-box-item__img"
                :src="require('../assets/images/' + item.img + '.png')"
                alt=""
              >
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MobileDetect from 'mobile-detect'
import get from 'lodash/get';

export default {
  name: 'Innovative',
  props: ['settings'],
  data() {
    return {
      showArrowScroll: true,
      activeSlideThumbs: 0,
      showRightArrow: true,
      showLeftArrow: true,
      sliderPageIndex: 1,
      showContentImgScrollDown: true,
      showContentImgScrollUp: false,
      swiperOptionTop: {
        slidesPerView: 1,
        loopedSlides: 5,
        longSwipesMs: 0,
        allowTouchMove: false,
        longSwipesRatio: 0,
        autoplay: true,
        delay: 3000,
        effect: 'fade',
        speed: 1150,
        on: {
          slideChange: this.slideChangeSwiper,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperOptionThumbs: {
        slidesPerView: 8,
        touchRatio: 0.2,
        loopedSlides: 5,
        slideToClickedSlide: false,
        on: {
          click: this.clickSwiper,
        },
        breakpoints: {
          991: {
            slidesPerView: 5,
          },
          600: {
            slidesPerView: 4,
          },
          480: {
            slidesPerView: 4,
          },
          375: {
            slidesPerView: 3,
          },
        },
      },
      activeItemId: 0,
      innovativeTabs: [
        {
          tabName: 'Events',
        },
        {
          tabName: 'Sermons',
        },
        {
          tabName: 'Give',
        },
        {
          tabName: 'Blog',
        },
        {
          tabName: 'Photos',
        },
        {
          tabName: 'News',
        },
        {
          tabName: 'Devotions',
        },
        {
          tabName: 'Ministries',
        },
        {
          tabName: 'Staff',
        },
        {
          tabName: 'Promo',
        },
        {
          tabName: 'About Us',
        },
        {
          tabName: 'Contact Us',
        },
        {
          tabName: 'Reading plan',
        },
      ],
      innovativeTabsContent: [
        {
          id: '1',
          name: 'Events',
          img: 'banner-events',
        },
        {
          id: '2',
          name: 'Sermons',
          img: 'banner-sermons',
        },
        {
          id: '3',
          name: 'Give',
          img: 'banner-give',
        },
        {
          id: '4',
          name: 'blog',
          img: 'banner-blogs',
        },
        {
          id: '5',
          name: 'Photos',
          img: 'banner-photos',
        },
        {
          id: '6',
          name: 'News',
          img: 'banner-news',
        },
        {
          id: '7',
          name: 'Devotion',
          img: 'banner-devotions',
        },
        {
          id: '8',
          name: 'Ministries',
          img: 'banner-ministries',
        },
        {
          id: '9',
          name: 'Staff',
          img: 'banner-staff',
        },
        {
          id: '10',
          name: 'Promo',
          img: 'banner-promo',
        },
        {
          id: '11',
          name: 'About us',
          img: 'banner-aboutus',
        },
        {
          id: '12',
          name: 'Contact us',
          img: 'banner-contactus',
        },
        {
          id: '13',
          name: 'Reading plan',
          img: 'banner-reading-plan',
        },
      ],
    };
  },
  computed: {
    title() {
      const title = get(this.settings, 'title', 'Groundbreaking and innovative - like nothing you have ever had before!');
      return title;
    },
    subTitle() {
      const subTitle = get(this.settings, 'subTitle', 'Cutting-edge, Ready-made Widgets & Page Templates designed for Performance & Growth.');
      return subTitle;
    },
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null,
        },
      );
      wow.init();
    });
  },
  beforeMount() {
    window.addEventListener('scroll', this.setWindowScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setWindowScroll);
  },
  methods: {
    setWindowScroll() {
      this.$nextTick(() => {
        // let slideNumberTwo = document.getElementById('slideNumberTwo')
        const galleryTop = document.getElementById('gallery-top');
        const galleryThumbs = document.getElementById('gallery-thumbs');
        let scrollDirection;
        if (this.windowOldScrollPosition <= window.scrollY) {
          scrollDirection = 'down';
        } else {
          scrollDirection = 'up';
        }
        if (scrollDirection === 'down') {
          if ((window.innerHeight >= galleryTop.getBoundingClientRect().y)) {
            if (this.showContentImgScrollDown) {
              if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
                this.activeSlideThumbs = 0;
                this.sliderPageIndex = 1;
                this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.autoplay.start();
                this.showContentImgScrollDown = false;
              }
            }
          } else if (!this.showContentImgScrollDown) {
            this.showContentImgScrollDown = true;
          }
        } else if ((galleryThumbs.getBoundingClientRect().bottom > 0)) {
          if (this.showContentImgScrollUp) {
            if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
              this.activeSlideThumbs = 0;
              this.sliderPageIndex = 1;
              this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.autoplay.start();
              this.showContentImgScrollUp = false;
            }
          }
        } else if (!this.showContentImgScrollUp) {
          this.showContentImgScrollUp = true;
        }
        this.windowOldScrollPosition = window.scrollY;
      });
    },
    clickSwiper() {
      this.activeSlideThumbs = this.$refs.swiperThumbs.swiper.clickedIndex;
      this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs);
      if (this.activeSlideThumbs > this.$refs.swiperThumbs.swiper.params.slidesPerView) {
        const indexSlidePage = Math.floor(this.activeSlideThumbs / this.$refs.swiperThumbs.swiper.params.slidesPerView);
        this.sliderPageIndex = indexSlidePage;
      }
      // this.activeIndexSlidePhoto = this.activeSlideThumbs
    },
    slideChangeSwiper() {
      this.activeSlideThumbs = this.$refs.swiperTop.swiper.activeIndex;
      if (this.activeSlideThumbs >= this.$refs.swiperThumbs.swiper.params.slidesPerView * this.sliderPageIndex) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex++;
      } else if (this.activeSlideThumbs === 0) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex = 1;
      }
    },
    slideSwiperTo(action) {
      if (action === 'next') {
        this.$refs.swiperThumbs.swiper.slideNext();
      } else {
        this.$refs.swiperThumbs.swiper.slidePrev();
      }
    },

  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';
  @import '../../node_modules/swiper/dist/css/swiper.css';

  .innovative {
    padding-bottom: 120px;
    background: $color-white;
    padding-top: 114px;
    position: relative;

    &__title {
      margin: 0 -50px;
    }

    .swiper-wrapper {
      transition-delay: 0ms !important;
    }

    &__arrow {
      color: $color-white;
      position: absolute;
      top: -80px;
      left: 42px;
      background: $color-moody-blue;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 16px;
      cursor: pointer;
      /*box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .3);*/
      opacity: 1;
      z-index: 100;
      transition: opacity .3s ease;

      &_top {
        top: 20px;
      }

      &:hover {
        opacity: .6;
      }
    }

    &__desc {
      margin-bottom: 63px;
      margin-top: 9px;
    }

    &-tabs {
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      padding: 0;
      top: 0;
      align-items: center;
      list-style: none;
      will-change: transform;
      transition: transform .6s cubic-bezier(.86, 0, .07, 1);

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        /*padding: 0 35px 15px;*/
        padding: 0 20px 15px;
        color: $color-alto;
        font-family: $font-global, sans-serif;
        font-size: 21px;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        transition: color .3s ease;

        &:hover {
          color: $color-moody-blue;
        }

        &:before {
          content: "";
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          /*right: 0;*/
          z-index: 3;
          /*width: 100%;*/
          height: 1px;
          background-color: transparent;
          border-radius: 1px;
          transition: all .3s ease-in;
          width: 0;
        }

        &_active {
          color: $color-moody-blue;

          &:before {
            background-color: $color-moody-blue !important;
            left: 0;
            width: 100%;
          }
        }
      }

      &-bar {
        user-select: none;
        position: relative;
        height: 40px;
        width: 100%;
        z-index: 1;
        max-width: 1061px;
        margin: auto;
        border-bottom: 1px solid $color-alto;
        margin-bottom: 56px;

        .tabs__wrapper {
          position: relative;
          overflow: visible;
          height: inherit;
        }
      }
    }

    &-box {
      position: relative;

      &-item {
        display: flex;
        justify-content: center;

        img {
          max-height: 700px;
        }
      }
    }
  }

  .swiper-button {

    &-next, &-prev {
      background: none;
      transition: color .3s ease;

      &:hover, &:focus {
        color: $color-moody-blue;
      }
    }

    &-prev {
      font-size: 12px;
      top: 17px;
      transform: rotate(90deg);
      color: $color-alto;
      left: -58px;
    }

    &-next {
      font-size: 12px;
      top: 12px;
      transform: rotate(-90deg);
      color: $color-alto;
      right: -54px;
    }
  }

  @media (max-width: 1199px) {
    .innovative {
      &__title {
        margin: 0;
      }
    }
  }

  @media (max-width: 1023px) {
    .swiper-button-next, .swiper-button-prev {
      height: 15px;
    }

    .swiper-button-prev {
      left: -25px;
      top: 30px;
    }

    .swiper-button-next {
      right: -25px;
      top: 26px;
    }
  }

  @media (max-width: 991px) {
    .innovative {

      &-box {
        height: 300px;

        &-item {
          height: 300px;

          img {
            height: 300px;
          }
        }
      }

      &__arrow {
        display: none !important;
        &_top {
          display: none !important;
          /*top: -80px;
          left: 15px;
          z-index: 1000;*/
        }
      }
    }
  }
  @media (max-width: 767px) {
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }

  @media (max-width: 480px) {
    .innovative {
      padding-top: 38px;
      padding-bottom: 50px;

      &__desc {
        margin-top: 15px;
        margin-bottom: 28px;
      }

      &-tabs {
        &__item {
          font-size: 16px;
          padding-bottom: 13px;
        }

        &-bar {
          height: 32px;
          margin-bottom: 8px;
        }
      }

      &-box {
        height: 180px;

        &-item {
          height: 180px;

          img {
            height: 180px;
          }
        }
      }
    }
  }
</style>
